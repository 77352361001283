













































































































































































































































































































































































.title_is_empty {
  position: absolute;
  top: 100px;
  font-size: 0.9rem;
  color: #f56c6c;
}

::v-deep .el-textarea__inner {
  height: 300px;
  resize: none;
}
